import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { takeWhile } from 'rxjs/operators';
import { AuthService } from './auth.service';
import {
  PermissionSetContainer,
  UserType,
  User_Permission,
  userTypeFromJSON,
  userTypeToJSON,
} from '../models';
import { getUserCollection } from '../misc/getUserCollection';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private authService: AuthService,
    private functions: Functions
  ) {}

  setPermission = (permission: PermissionSetContainer) =>
    new Promise((resolve, reject) => {
      if (!permission.permission?.user) {
        throw new Error('permission user missing');
      }

      if (permission.permission?.roles?.admin) {
        permission.permission.user.canImpersonate = true;
      }

      const insertBody = PermissionSetContainer.toJSON(permission);

      console.log('insertBody', insertBody);

      httpsCallable(this.functions, 'permissionInvite')(insertBody)
        .then(resolve)
        .catch(reject);
    });

  updateUser = async (
    grantedUserType: UserType,
    grantedUserId: string,
    toUpdatePermission: Partial<UpdateBody>
  ) => {
    const user = await this.authService.getCurrentUser();

    if (!user || !user.userType) {
      console.error('not authenticated');
      return;
    }

    const insertBody: { [key: string]: unknown } = {
      granterUserId: user.id,
      granterUserType: userTypeToJSON(user.userType),
      grantedUserId: grantedUserId,
      grantedUserType: userTypeToJSON(grantedUserType),
      toUpdate: User_Permission.toJSON(
        User_Permission.fromPartial(toUpdatePermission as User_Permission)
      ),
    };

    if (toUpdatePermission.password) {
      if (!insertBody['toUpdate']) {
        insertBody['toUpdate'] = {};
      }
      (insertBody['toUpdate'] as { password: string }).password =
        toUpdatePermission.password;
    }

    console.log('insertBody', insertBody);

    const result = await httpsCallable(
      this.functions,
      'permissionUpdate'
    )(insertBody);

    console.log('update permissions res', result);

    return result;
  };

  setPermissionByTerm = (
    term: string,
    role: string | string[],
    options: { grantChildAccess?: boolean; canImpersonate?: boolean } = {}
  ): Promise<{ data: { ok: boolean } }> =>
    new Promise((resolve, reject) => {
      const { grantChildAccess, canImpersonate } = options;

      this.authService.currentUser$
        .pipe(takeWhile(u => !u?.id, true))
        .subscribe(user => {
          if (!user) {
            console.error('not authenticated');
            return;
          }
          const userCollection = getUserCollection(user.userType);

          const insertBody: { [key: string]: unknown } = {
            userCollection,
            userType: userTypeFromJSON(user.userType),
            userId: user.id,
            term,
            canImpersonate,
            grantChildAccess,
          };

          if (role instanceof Array) {
            insertBody['roles'] = role.reduce(
              (acc, role) => {
                acc[role] = true;
                return acc;
              },
              {} as { [key: string]: boolean }
            );
          } else {
            insertBody['role'] = role;
          }

          httpsCallable(
            this.functions,
            'permissionSet'
          )(insertBody)
            .then(res => resolve(res as { data: { ok: boolean } }))
            .catch(reject);
        });
    });

  acceptInvitation = (
    permission: User_Permission | User_Permission[],
    grantedUserId?: string
  ) =>
    new Promise((resolve, reject) => {
      this.authService.currentUser$
        .pipe(takeWhile(u => !u?.id, true))
        .subscribe(() => {
          const permissions =
            permission instanceof Array ? permission : [permission];

          if (permissions.length === 0) {
            console.error('permissions is empty');
            return;
          }

          const acceptBodies = [];
          for (const permission of permissions) {
            if (!permission.user) {
              console.error('permission.user is undefined');
              return;
            }

            if (!permission.user.userType) {
              console.error('permission.user.userType is undefined');
              return;
            }

            const acceptBody: {
              [key: string]: unknown;
            } = {
              granterUserId: permission.user?.id,
              granterUserType: userTypeToJSON(permission.user.userType),
              grantedUserId,
            };

            if (permission.grantedUserId) {
              acceptBody['grantedUserId'] = permission.grantedUserId;
            }
            if (permission.grantedUserType) {
              acceptBody['grantedUserType'] = permission.grantedUserType;
            }

            if (permission.grantChildAccess) {
              acceptBody['grantChildAccess'] = permission.grantChildAccess;
            }
            acceptBodies.push(acceptBody);
          }

          httpsCallable(this.functions, 'permissionAccept')(acceptBodies)
            .then(resolve)
            .catch(reject);
        });
    });

  claimPermission = (permission: User_Permission | User_Permission[]) =>
    new Promise((resolve, reject) => {
      this.authService.currentUser$
        .pipe(takeWhile(u => !u?.id, true))
        .subscribe(user => {
          if (!user) {
            console.error('not authenticated');
            return;
          }

          const permissions =
            permission instanceof Array ? permission : [permission];

          if (permissions.length === 0) {
            console.error('permissions is empty');
            return;
          }

          const acceptBodies = [];
          for (const permission of permissions) {
            if (!permission.user) {
              console.error('permission.user is undefined');
              return;
            }

            if (!permission.user.userType) {
              console.error('permission.user.userType is undefined');
              return;
            }

            const acceptBody: {
              [key: string]: unknown;
            } = {
              granterUserId: permission.user?.id,
              granterUserType: userTypeToJSON(permission.user.userType),
              grantedUserId: permission.grantedUserId,
              grantedUserType: permission.grantedUserType,
            };

            if (permission.grantChildAccess) {
              acceptBody['grantChildAccess'] = permission.grantChildAccess;
            }
            acceptBodies.push(acceptBody);
          }

          httpsCallable(this.functions, 'permissionClaim')(acceptBodies)
            .then(resolve)
            .catch(reject);
        });
    });

  deletePermission = (grantedUserId: string, grantedUserCollection?: string) =>
    new Promise((resolve, reject) => {
      this.authService.currentUser$
        .pipe(takeWhile(u => !u?.id, true))
        .subscribe(user => {
          if (!user) {
            console.error('not authenticated');
            return;
          }
          const userCollection = getUserCollection(user.userType);

          httpsCallable(
            this.functions,
            'permissionDelete'
          )({
            userCollection,
            userId: user.id,
            grantedUserId: grantedUserId,
            grantedUserCollection: grantedUserCollection,
          })
            .then(resolve)
            .catch(reject);
        });
    });
}

interface UpdateBody extends User_Permission {
  password?: string;
}
